import api from '@api'
import { isLoggedIn } from '@utils/auth'
import { generateId } from '@utils/id'
import { getRootTranslator } from '@utils/get-module-translator'
import {
  buildRelationalQualificationStructure,
  buildFlatQualificationStructure,
} from '@data/qualification'
import {
  transformUserConsent,
  transformUserConsentForServer,
} from '@data/privacy-setting'

const __rootT = getRootTranslator()

export function getUserConsentApi() {
  return api
    .get(`${isLoggedIn() ? '/' : '/public/'}user/consentsetting`)
    .then(transformUserConsent)
}

export function updateUserConsentApi(data) {
  return api
    .patch(`/user/consentsetting`, transformUserConsentForServer(data), {
      message: __rootT('updated_successfully', {
        resource: __rootT('user_consent'),
      }),
    })
    .then(transformUserConsent)
}

export function getPiiFieldsApi(filters, limit, offset) {
  return api
    .post(
      'field/piifields/search/byqual',
      {
        qualDetails: filters.name
          ? buildFlatQualificationStructure([
              buildRelationalQualificationStructure(
                'name',
                'contains',
                filters.name,
                'string',
                'db'
              ),
            ])
          : undefined,
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
        },
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map((i) => ({
          id: generateId(),
          name: i.fieldName,
          moduleName: i.model,
        })),
        total: data.totalCount,
      }
    })
}
