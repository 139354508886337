import api from '@api'

import {
  getModuleTranslator,
  getRootTranslator,
} from '@utils/get-module-translator'
import { isPortalLogin } from '@utils/auth'

export const passwordPolicyInputAttrs = () => {
  return {
    Low: {
      minLength: {
        min: 8,
        rules: {
          required: true,
          min_value: 8,
          max_value: 15,
        },
        disabled: false,
      },
      minUpperCaseLength: {
        min: 1,
        rules: {
          required: true,
          min_value: 1,
          max_value: 15,
        },
        disabled: true,
      },
      minLowerCaseLength: {
        min: 1,
        rules: {
          required: true,
          min_value: 1,
          max_value: 15,
        },
        disabled: true,
      },
      minNumericCharacterLength: {
        min: 1,
        rules: {
          required: true,
          min_value: 1,
          max_value: 15,
        },
        disabled: true,
      },
      minSpecialCharacterLength: {
        min: 0,
        rules: {
          required: true,
          min_value: 0,
          max_value: 15,
        },
        disabled: true,
      },
    },
    Medium: {
      minLength: {
        min: 8,
        rules: {
          required: true,
          min_value: 8,
          max_value: 15,
        },
        disabled: false,
      },
      minUpperCaseLength: {
        min: 1,
        rules: {
          required: true,
          min_value: 1,
          max_value: 15,
        },
        disabled: true,
      },
      minLowerCaseLength: {
        min: 1,
        rules: {
          required: true,
          min_value: 1,
          max_value: 15,
        },
        disabled: true,
      },
      minNumericCharacterLength: {
        min: 1,
        rules: {
          required: true,
          min_value: 1,
          max_value: 15,
        },
        disabled: true,
      },
      minSpecialCharacterLength: {
        min: 1,
        rules: {
          required: true,
          min_value: 1,
          max_value: 15,
        },
        disabled: true,
      },
    },
    High: {
      minLength: {
        min: 8,
        rules: {
          required: true,
          min_value: 8,
          max_value: 15,
        },
        disabled: false,
      },
      minUpperCaseLength: {
        min: 1,
        rules: {
          required: true,
          min_value: 1,
          max_value: 15,
        },
        disabled: true,
      },
      minLowerCaseLength: {
        min: 2,
        rules: {
          required: true,
          min_value: 2,
          max_value: 15,
        },
        disabled: true,
      },
      minNumericCharacterLength: {
        min: 1,
        rules: {
          required: true,
          min_value: 1,
          max_value: 15,
        },
        disabled: true,
      },
      minSpecialCharacterLength: {
        min: 2,
        rules: {
          required: true,
          min_value: 2,
          max_value: 15,
        },
        disabled: true,
      },
    },
    Custom: {
      minLength: {
        min: 1,
        rules: {
          required: true,
          min_value: 1,
          max_value: 15,
        },
      },
      minUpperCaseLength: {
        min: 0,
        rules: {
          required: true,
          min_value: 0,
          max_value: 15,
        },
        disabled: false,
      },
      minLowerCaseLength: {
        min: 0,
        rules: {
          required: true,
          min_value: 0,
          max_value: 15,
        },
        disabled: false,
      },
      minNumericCharacterLength: {
        min: 0,
        rules: {
          required: true,
          min_value: 0,
          max_value: 15,
        },
        disabled: false,
      },
      minSpecialCharacterLength: {
        min: 0,
        rules: {
          required: true,
          min_value: 0,
          max_value: 15,
        },
        disabled: false,
      },
    },
  }
}

const transformPasswordPolicy = (policy) => {
  return {
    id: policy.id,
    // minLength: policy.passwordMinLength,
    // enableSpecialChar: policy.haveSpecialChar,
    // enableAlphabetAndNumber: policy.haveAlphabetAndNumber,
    // enableMixCase: policy.haveMixCase,
    // expiryDays: policy.passwordExpiry,
    // doNotallowUserToUsePreviousPassword:
    //   policy.doNotallowUserToUsePreviousPassword,
    // previousPassword: policy.previousPassword,
    // new properties
    name: policy.name,
    text: policy.name,
    key: policy.id,
    systemName: policy.name,
    minLength: policy.passwordMinLength,
    minUpperCaseLength: policy.minimumUpperCaseCharacters,
    minLowerCaseLength: policy.minimumLowerCaseCharacters,
    minSpecialCharacterLength: policy.minimumSpecialCharacters,
    minNumericCharacterLength: policy.minimumNumericCharacters,
    expiryDays: policy.passwordExpiry,
    previousPassword: policy.previousPassword,
    doNotallowUserToUsePreviousPassword:
      policy.doNotallowUserToUsePreviousPassword,
    restrictSameASUsername: policy.restrictSameASUsername,
    enabled: policy.enabled,
    canEdit: policy.updatebleOob,
    canDelete: policy.deleteableOob,
  }
}

export const transformPasswordPolicyForServer = (policy) => {
  return {
    id: policy.id,
    name: policy.name,
    passwordMinLength: policy.minLength,
    minimumUpperCaseCharacters: policy.minUpperCaseLength,
    minimumLowerCaseCharacters: policy.minLowerCaseLength,
    minimumSpecialCharacters: policy.minSpecialCharacterLength,
    minimumNumericCharacters: policy.minNumericCharacterLength,
    passwordExpiry: policy.expiryDays,
    previousPassword: policy.previousPassword,
    doNotallowUserToUsePreviousPassword:
      policy.doNotallowUserToUsePreviousPassword,
    restrictSameASUsername: policy.restrictSameASUsername,
    enabled: policy.enabled,
  }
}

const __rootTc = getModuleTranslator()
const __rootT = getRootTranslator()

export function getPasswordPolicyApi() {
  return api
    .get(`${isPortalLogin() ? 'public' : ''}/passwordPolicy`)
    .then(transformPasswordPolicy)
}

export function upadatePasswordPolicyApi(data) {
  return api.patch(
    `passwordPolicy/${data.id}`,
    transformPasswordPolicyForServer(data),
    {
      message: __rootT('updated_successfully', {
        resource: `${__rootTc('password_policy')}`,
      }),
    }
  )
}

export function passwordPolicySearchByQualApi(limit, offset) {
  return api
    .post(
      'passwordPolicy/search/byqual',
      {},
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
        },
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformPasswordPolicy),
        total: data.totalCount,
      }
    })
}
