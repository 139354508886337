import api from '@api'
import { FILESERVER_HOST_SUFFIX } from '@modules/patch-management/helpers/utils'

import {
  // getModuleTranslator,
  getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'
// import moduleConfig from './config'
import {
  buildFlatQualificationStructure,
  buildRelationalQualificationStructure,
  buildNameFilterQuery,
} from '@data/qualification'
import {
  transformProxyConfig,
  transformProxyConfigForServer,
  transformPatch,
  transformPatchForServer,
  transformSystemHealthSettings,
  transformStorageConfig,
  transformStorageConfigForServer,
  transformComputerGroup,
  transformComputerGroupForServer,
  transformLanguage,
  transformAllLanguage,
  transformDeploymentNotification,
  transformDeploymentNotificationForServer,
  transformBandwidthUtilization,
  transformBandwidthUtilizationForServer,
  transformRelayServerConfiguration,
  transformRelayServerConfigurationForServer,
  transformPatchAudit,
} from '@data/patch-management'
import {
  transformPatchApprovalPolicy,
  transformPatchApprovalPolicyForServer,
} from '@data/approval-policy'
import { successToast } from '@motadata/ui'
// const __t = getModuleTranslator(moduleConfig.translationKey)
const __rootT = getRootTranslator()
const __rootTc = getRootPluaralTranslator()

export function createProxyServerConfigApi(data) {
  return api
    .post('/proxyserver', transformProxyConfigForServer(data), {
      message: __rootT('added_successfully', {
        resource: `${__rootTc('proxy')} ${__rootTc('server')} ${__rootTc(
          'configuration'
        )}`,
      }),
    })
    .then(transformProxyConfig)
}
const buildQualifications = (qualificationFactors) => {
  const quals = []
  if (qualificationFactors.name) {
    quals.push(
      buildRelationalQualificationStructure(
        'name',
        'contains',
        qualificationFactors.name,
        'string',
        'db'
      )
    )
  }
  if (qualificationFactors.enabled) {
    quals.push(
      buildRelationalQualificationStructure(
        'disabled',
        'equal',
        false,
        'boolean',
        'db'
      )
    )
  }
  if (qualificationFactors.types) {
    quals.push(
      buildRelationalQualificationStructure(
        'proxyType',
        'in',
        qualificationFactors.types,
        'enum',
        'db'
      )
    )
  }

  return buildFlatQualificationStructure(quals)
}
export function getProxyServerConfigApi(filter, limit, offset) {
  return api
    .post(
      '/proxyserver/search/byqual',
      {
        qualDetails:
          filter.name || filter.enabled || filter.types
            ? buildQualifications(filter)
            : undefined,
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformProxyConfig),
        total: data.totalCount,
      }
    })
}
const buildQualificationsForComputerGroup = (qualificationFactors) => {
  const quals = []
  if (qualificationFactors.name) {
    quals.push(buildNameFilterQuery(qualificationFactors.name))
  }
  if (
    qualificationFactors.selectedName &&
    qualificationFactors.selectedName.length
  ) {
    quals.push(buildNameFilterQuery(qualificationFactors.selectedName, 'in'))
  }
  if (qualificationFactors.ids) {
    quals.push(
      buildRelationalQualificationStructure(
        'id',
        'in',
        qualificationFactors.ids,
        'long',
        'db'
      )
    )
  }
  return buildFlatQualificationStructure(quals)
}

const buildQualificationsForLanguage = (qualifications) => {
  const quals = []
  if (qualifications.ids) {
    quals.push(
      buildRelationalQualificationStructure(
        'id',
        'in',
        qualifications.ids,
        'long',
        'db'
      )
    )
  }
  return buildFlatQualificationStructure(quals)
}

export function saveProxyServerConfigApi(config) {
  return api
    .patch(`/proxyserver/${config.id}`, transformProxyConfigForServer(config), {
      message: __rootT('saved_successfully', {
        resource: `${__rootTc('proxy')} ${__rootTc('server')} ${__rootTc(
          'configuration'
        )}`,
      }),
    })
    .then(transformProxyConfig)
}
export function deleteProxyServerApi(id) {
  return api.delete(`/proxyserver/${id}`, {
    message: __rootT('deleted_successfully', {
      resource: `${__rootTc('proxy')} ${__rootTc('server')} ${__rootTc(
        'configuration'
      )}`,
    }),
  })
}
export function testProxyServerConfigApi(config) {
  return api.post('/proxyserver/testconnection', transformProxyConfig(config), {
    notify: false,
  })
}

export function getPatchApprovalPolicyApi() {
  return api.get('/patchapprovalsetting').then(transformPatchApprovalPolicy)
}

export function updatePatchApprovalPolicyApi(data) {
  return api
    .patch(
      `/patchapprovalsetting`,
      transformPatchApprovalPolicyForServer(data),
      {
        message: __rootT('updated_successfully', {
          resource: __rootTc('patch_approval_policy'),
        }),
      }
    )
    .then(transformPatchApprovalPolicy)
}

export function getBandwidthUtilizationApi() {
  return api.get('/bandwidthlimit/setting').then(transformBandwidthUtilization)
}

export function updateBandwidthUtilizationApi(data) {
  return api.patch(
    '/bandwidthlimit/setting',
    transformBandwidthUtilizationForServer(data),
    {
      message: __rootT('updated_successfully', {
        resource: __rootTc('bandwidth_utilization'),
      }),
    }
  )
}

// Relay Server Configuration
export function getRelayServerConfigurationApi() {
  return api
    .get('/relayserverconfiguration')
    .then(transformRelayServerConfiguration)
}

export function updateRelayServerConfigurationApi(data) {
  return api.patch(
    '/relayserverconfiguration',
    transformRelayServerConfigurationForServer(data),
    {
      message: __rootT('updated_successfully', {
        resource: __rootTc('relay_server_settings'),
      }),
    }
  )
}
export function getAgentsApi() {
  return api.get('/endpointscope')
}

// Add end point scope
export function updateComputerApi(data) {
  return api
    .post('/endpointscope/bulk/create', { objectIds: data.agentIds })
    .then((data) => {
      if (data.successIds) {
        successToast(
          __rootT('added_successfully', {
            resource: `${
              data.agentIds && data.agentIds.length > 1
                ? __rootTc('computer', 2)
                : __rootTc('computer')
            }`,
          })
        )
      }
      return {
        failed: Object.keys(data.failerIds || {}).map((key) => ({
          id: parseInt(key, 10),
          message: data.failerIds[key].userMessage,
        })),
      }
    })
}

// Delete end point scope
export function deleteComputerApi(ids) {
  return api.delete(`/endpointscope/bulk/delete`, {
    data: { objectIds: ids },
    message: __rootT('removed_successfully', {
      resource: `${
        ids.length > 1 ? __rootTc('computer', 2) : __rootTc('computer')
      }`,
    }),
  })
}

export function getPatchDetailsApi() {
  return api.get(`/patchdbsettings`).then(transformPatch)
}

export function getThirdPartyApplicationsApi() {
  return api.get(`/thirdparty/application/supportedData`)
}

export function updatePatchApi(data) {
  return api
    .patch(`/patchdbsettings`, transformPatchForServer(data), {
      message: __rootT('updated_successfully', {
        resource: `${__rootTc('patch')} ${__rootTc('details')}`,
      }),
    })
    .then(transformPatch)
}

export function updatePatchManuallApi() {
  return api.get('/patchdbsettings/manualupdate')
}

export function getProcessStatusApi() {
  return api.get('/patchdbsettings/isrunning')
}

export function getSystemHealthSettingsApi() {
  return api.get('/systemHealthPolicy').then(transformSystemHealthSettings)
}

export function updateSystemHealthSettingsApi(data) {
  return api
    .patch('/systemHealthPolicy', transformSystemHealthSettings(data), {
      message: __rootT('updated_successfully', {
        resource: __rootTc('system_health_settings'),
      }),
    })
    .then(transformSystemHealthSettings)
}

export function getStorageConfigApi() {
  return api.get('/storageconfiguration').then(transformStorageConfig)
}
export function testConnectionApi(data) {
  return api
    .post(`/storageconfiguration/testconnection`, {
      host: `${data.host}${FILESERVER_HOST_SUFFIX}`,
      port: data.port,
    })
    .then((data) => {
      return {
        connected: data.connected,
        keyValueRests: data.keyValueRests,
      }
    })
}
export function updateStorageConfigApi(data) {
  return api
    .patch(`storageconfiguration`, transformStorageConfigForServer(data), {
      message: __rootT('updated_successfully', {
        resource: __rootTc('patch_storage_config'),
      }),
    })
    .then(transformStorageConfig)
}

export function getComputerGroupsApi(
  filter,
  limit,
  offset,
  additionaParams = {}
) {
  return api
    .post(
      '/computergroup/search/byqual',
      {
        qualDetails:
          filter.name || filter.selectedName || filter.ids
            ? buildQualificationsForComputerGroup(filter)
            : undefined,
      },
      {
        params: {
          offset: offset || 0,
          size: limit || 1000,
          ...additionaParams,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformComputerGroup),
        total: data.totalCount,
      }
    })
}

export function updateComputerGroupsApi(data) {
  return api
    .patch(`/computergroup/${data.id}`, transformComputerGroupForServer(data), {
      message: __rootT('updated_successfully', {
        resource: `${
          data.agentIds.length > 1
            ? __rootTc('computer', 2)
            : __rootTc('computer')
        }`,
      }),
    })
    .then(transformComputerGroup)
}

export function deleteComputerGroupApi(id) {
  return api.delete(`/computergroup/${id}`, {
    message: __rootT('removed_successfully', {
      resource: `${__rootTc('computer')} ${__rootTc('group')}`,
    }),
  })
}

export function createComputerGroupApi(data) {
  return api
    .post('/computergroup', transformComputerGroupForServer(data), {
      message: __rootT('created_successfully', {
        resource: __rootTc(`${__rootTc('computer')} ${__rootTc('group')}`),
      }),
    })
    .then(transformComputerGroup)
}

export function getComputerGroupDetailApi(id) {
  return api.get(`/computergroup/${id}`).then(transformComputerGroup)
}

export function getLanguagesApi(filters) {
  return api
    .post(
      '/patchlanguage/search/byqual',
      filters.ids
        ? { qualDetails: buildQualificationsForLanguage(filters) }
        : {},
      {
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformLanguage),
        total: data.totalCount,
      }
    })
}

export function getAllLanguagesApi() {
  return api.get('/patchlanguage/allname').then(transformAllLanguage)
}
export function getDeploymentNotificationApi() {
  return api
    .get('/deploymentnotification')
    .then(transformDeploymentNotification)
}

export function updateDeploymentNotificationApi(data) {
  return api
    .patch(
      `/deploymentnotification`,
      transformDeploymentNotificationForServer(data),
      {
        message: __rootT('updated_successfully', {
          resource: `${__rootTc('deployment')} ${__rootTc('notification')}`,
        }),
      }
    )
    .then(transformDeploymentNotification)
}

export function getPatchAuditApi(filterFactor, pageSize, offset) {
  return api
    .post(`/configurationactivity`, filterFactor, {
      params: {
        size: pageSize || 1000,
        offset: offset || 0,
      },
    })
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformPatchAudit),
        total: data.totalCount,
      }
    })
}
